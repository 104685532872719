export const mainnetContractConfig = [
  {
    "CONTRACT_VERSION": "V3",
    "CORE_CONTRACT_ADDRESS": "0x440E1B5A98332BcA7564DbffA4146f976CE75397",
    "MINT_CONTRACT_ADDRESS": "0xa2ccfE293bc2CDD78D8166a82D1e18cD2148122b",
    "MEDIA_URL": "https://plottables-flex-mainnet.s3.amazonaws.com",
    "TOKEN_URL": "https://token.artblocks.io",
    "GENERATOR_URL": "https://generator.artblocks.io",
    "EDIT_PROJECT_URL": "https://artblocks.io/engine/flex/projects"
  },
  // {
  //   "CONTRACT_VERSION": "V3",
  //   "CORE_CONTRACT_ADDRESS": "0x440E1B5A98332BcA7564DbffA4146f976CE75397",
  //   "MINT_CONTRACT_ADDRESS": "0xa2ccfE293bc2CDD78D8166a82D1e18cD2148122b",
  //   "MEDIA_URL": "https://plottables-flex-mainnet.s3.amazonaws.com",
  //   "TOKEN_URL": "https://token.artblocks.io",
  //   "GENERATOR_URL": "https://generator.artblocks.io",
  //   "EDIT_PROJECT_URL": "https://artblocks.io/engine/flex/projects"
  // }
];

export const testnetContractConfig = [
//   {
//     CONTRACT_VERSION: 'V3',
//     CORE_CONTRACT_ADDRESS: '0x9b2e24Bcb09AaDa3e8EE4F56D77713453aFd8A98',
//     MINT_CONTRACT_ADDRESS: '0xD1d9aD8B1B520F19DFE43Cc975b9470840e8b824',
//     MEDIA_URL: 'https://plottablesv3-goerli.s3.amazonaws.com',
//     TOKEN_URL: 'https://token.staging.artblocks.io',
//     GENERATOR_URL: 'https://generator-staging-goerli.artblocks.io',
//     EDIT_PROJECT_URL:
//       'https://artist-staging.artblocks.io/engine/fullyonchain/projects'
//   }
];
