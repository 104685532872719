import { useEffect, useState } from 'react';
import { useAccount, useBalance, useContractReads } from 'wagmi';
import { BigNumber, utils } from 'ethers';
import { Box } from '@mui/material';
import GenArt721CoreV3_EngineABI from 'abi/V3/GenArt721CoreV3_Engine.json';
import MinterMerkleV5ABI from 'abi/V3/MinterMerkleV5.json';
import MintingProgress from 'components/MintingProgress';
import MintingPrice from 'components/MintingPrice';
import MinterMerkleV5Button from 'components/MinterButtons/MinterMerkleV5Button';
import { MerkleTree } from 'merkletreejs';
import { keccak256, solidityKeccak256 } from 'ethers/lib/utils';
// import { MERKLE_PROOF_API_URL } from 'config';

interface Props {
  coreContractAddress: string;
  mintContractAddress: string;
  projectId: string;
  artistAddress: string;
  scriptAspectRatio: number;
}

const hashAddress = (address: string) => {
  address = address.toLowerCase();
  return Buffer.from(solidityKeccak256(['address'], [address]).slice(2), 'hex');
};

export const getMerkleRoot = (addresses: string[]): string => {
  const merkleTree = new MerkleTree(
    addresses.map((addr: string) => hashAddress(addr)),
    keccak256,
    { sortPairs: true }
  );
  const root = merkleTree.getHexRoot();
  return root;
};

const generateUserMerkleProof = (addresses: any, userAddress: any): any => {
  const merkleTree = new MerkleTree(
    addresses.map((addr: any) => hashAddress(addr)),
    keccak256,
    {
      sortPairs: true
    }
  );
  return merkleTree.getHexProof(hashAddress(userAddress));
};

const allowlistAddresses = [
  '0x006a3acd3ebae6403ac8972b0813c2e9c5b803d9',
  '0x00de941478bce28995318726a7a727935105d5dd',
  '0x017347cb75ac8725608e593ea35d83f9b2b3cfb8',
  '0x019e03600d1b28e233536a866837bfa067579697',
  '0x01d8a4aadba222244721f6784394b74cbe8c2512',
  '0x02aaa417b43f9a23e772276c31c5bd7ed9a682bc',
  '0x04490fb053a8ff110bea35f22d955c0092aae5f8',
  '0x04c95c6f8ea7ff7c3f7a2f148fae75650c5a875e',
  '0x0945c91d5ddc3cdfdf7fdd45ded0746d0f31296e',
  '0x09e3ab684e211596e63fc839be72909b33d1c852',
  '0x0aeb17681de05ce61136b96c86f84fe5adc63f8f',
  '0x0b313ead65233ddb8dddac125c324f9f58e4f74c',
  '0x0b4990b3ac5d1d00e32711d421543975ad7a9906',
  '0x0c664c03eebcecb6c21e3b3bc77c9dffed5bd694',
  '0x0c973219372daabc365e3b97e453a500c482264e',
  '0x0cc5428702c220cc4a68dfb6c83c6930f7270e4a',
  '0x0cde89a91d6fd05c35f593f029b2be2d5b5eb075',
  '0x0ce390f18af702cca546297845a4a51d102123cf',
  '0x0e0bf22e3595687a8756e1e9862a20944d0be57e',
  '0x0ed39b4c8ca3f0faf74c780e8ec128314ee7f514',
  '0x0f0eae91990140c560d4156db4f00c854dc8f09e',
  '0x0fb3fa40850c1e472b6eced8fb10781763d42193',
  '0x0fb7f9eb8e9bee4dd70dc334b94dee2621a32fb3',
  '0x101864C31faBACb4138400d121AC07253E482dA8',
  '0x1134528d239cf419f9081ceebc3430ac191c318f',
  '0x113c6e8d6acb173da6ced275c00aaead1fa831de',
  '0x115894b2859e58419d1ef4787a79a431ce2615c3',
  '0x117dde66c2de2979ba4619d8d494fd20ffe96c2e',
  '0x1340fc92d9f817d41f76a5a90c188d8633c307d3',
  '0x14ddb398ce7a516c399a95b5d5c9a41326ec93de',
  '0x15619273a8826b4bafa17499c3c49aa5c7e78d96',
  '0x1796a702f8bee364846735a10c7daf62eed34a67',
  '0x18302a9b14d7340f770fb85ca623f5a9a2269484',
  '0x1986521f6caf7e3dbeab041e0578c62da5dd11af',
  '0x1a4b7409e020140443a02ef26858a7e53ac7d652',
  '0x1aa1c4fdb3b3de2b005a363281d0419e10d1b173',
  '0x1aa760cf97f1389e03a2c8b4ae7aa1d748d1b666',
  '0x1aae1bc09e1785c8cb00650db0c6015bd73b0c7e',
  '0x1ad98f60a98c8bf63f9be76085b08145eef502c1',
  '0x1ae5f1c9939a5fc8928fc544c4cbb667fb505daf',
  '0x1b10bd073ccaa7e88b4805e1484734fb1166c528',
  '0x1c5762f9899b46c19d4c9b8fbdd38d35856227f3',
  '0x1d10b0166a761f269adf864ebdaca9be445ca637',
  '0x1eb575e1b7212a05907a692de46986da95c5f22b',
  '0x1ed4447ebf072d4052263164e732af0e3930a709',
  '0x1f6f623b9348b3ca47bf7c25a975959c3d5668cf',
  '0x1fb32a867ef50436db4953b768f5a3f80bc52446',
  '0x20be12a62c45311c639b56308c5101e4f5353c4f',
  '0x2117bf88b4cb0186eaa87500a045fc998290e42a',
  '0x23099e3c2b7b8736ca8c428f01c7d2a6b851ed14',
  '0x23602ca06e977c86339ffddad74966e824ab691e',
  '0x24d6ff72eccab41724d488a03e8ea721ec3177a3',
  '0x25902aad3d4c12b368c18e8b6b02445ebd2d117b',
  '0x261b89e16244fa9a1288e75d06544992a7d63768',
  '0x2653b629bf3ac41994e8f17e7fecc9b803c78b26',
  '0x26f87915216d0d7375c9febd8a63bb90d1b503c5',
  '0x27fb0a35ac158fe460b91b04e5087b0131352ed9',
  '0x287bdf8c332d44bb015f8b4deb6513010c951f39',
  '0x32b6Cc0B1D20703F551e9572c1a2cBeC58063d4a',
  '0x2ac81c02fbfa8fc432d572f2894ea61554d11dd0',
  '0x2b91f332616084654098d4c13ceb9e82c8b73848',
  '0x2c31159f1497aed2cf9c028e55416f3050db1dff',
  '0x2d0ddb67b7d551afa7c8fa4d31f86da9cc947450',
  '0x2d11aae00487160cbc5b41442a0b14b5e1ac2d1d',
  '0x2d20d3b4cb133f05eeb389cd29c292a7357aa951',
  '0x2ea8c753948667d9626e17b354b38bff721c02a3',
  '0x2f6f2cafef028632108e2bb00da0da8cba09844f',
  '0x301b275e3b20f9eb9dbb54e6f0df0f4e01101e14',
  '0x301cc218009608e59f9647f4b7dd8a49be8493af',
  '0x307fb0634215379f9078404afe70034ef8142e2f',
  '0x30a007515214391a221cd50ce554092cf637f398',
  '0x30a898d50f18f317ecf6bf50240a34a416a7b1b4',
  '0x311f2b05f8c9dacf4443da9951443664368e726b',
  '0x32084ce62e8955da04ce2a2969bd6edbb4b346a5',
  '0x3330777cc5270db65e7df1d738e989dc16dd49f2',
  '0x334c036e3125dcb6d93e9b998f6d9d7a1be4b17f',
  '0x340dc2e57d64e4f8a7010eb347fd1ca1b18bf935',
  '0x348d43bd8daeb9f13611d5f0838225e6a9d89bd0',
  '0x34cf7ac942a815ddeddf1319ce91dea69af46dcb',
  '0x34d31c441d418dc041a37907a6f32b68c0bf503d',
  '0x3564ce014967c81e25f6349124673cf60b7ac572',
  '0x35c92a2447de0576399aa34b74d2cb26af950a69',
  '0x35fe2a980a124242db3b45421b4e27be05962b91',
  '0x367ff64da0668d86e7ac5d43dd9459fd4ce381d6',
  '0x36a1eccfeb099c0476a24df1ea86c8007bd7acbe',
  '0x36be14c0d731d70333e4f906a0d33ef65182fd0d',
  '0x36e10f7bb46a7e8a9000e20ac6bdc20721d15f1a',
  '0x36e166e665fa39a1cf7fa26eb2f37ec86c84d24c',
  '0x37009f9ae9dcc0a6d59204e1aeaf9da4968caec8',
  '0x37ba996dd7eb738af38af7839a17ad19cad5f155',
  '0x3840f067df8a1900a04051fb4bc639f7f83d519d',
  '0x3a661a18cb0258119636dfdde098648c6ad5ba62',
  '0x3b19fb4f043e40d28bd357266f7dbb8a1a9bc644',
  '0x3b6c5304180c241cc8697b48c484176cd2dd0d02',
  '0x3e7374eE60f296Ef2350c47A5110cb5233321756',
  '0x3e7d1b6527b3b3555aaf149935f2d029abf843d7',
  '0x3f358b4e54385ba4fc8dd9ea718d360db5c7fda5',
  '0x414a61f858434e822f18058e13d6a5aefa9501c0',
  '0x414ae75fc968d90fa82f0402a55603d1b5186c37',
  '0x423232b60d97d4a989a47ee5fefd47c8dc4b0e1d',
  '0x4257564c6a110a896168a42efee4c5ae3dfd26f0',
  '0x43105a71c046116ed80ef4ea323a6a476c5545b6',
  '0x4393f261eede0a2bb7fe3758dd11cc70fc417d7a',
  '0x44c2a80f8b924858db469a5b35128908e55c531f',
  '0x4510a4c5f0992ca38458c83050ad7f1b9a258822',
  '0x45492c6bc6ab97e4320e6f844c560be62737a303',
  '0x46c7de8f81d756e0de13dff258ea17929fb20851',
  '0x478454599df852e11524083605457875e50ceea8',
  '0x47b6475df0c52306b5970dcc5e11c913c0b8f805',
  '0x47d278101ee9335c3a3baa14fe184f757229a7b8',
  '0x481b1e34fc679b94e78571de1369d8ebe4bacdbf',
  '0x482293e4c21130f069169e5d7e9c99f239c5ee8e',
  '0x48a3c3187975058eabe2d1bd7535d4a26e3250ba',
  '0x497FaEB2c26F1661f9D2D21ea696Df081B46a712',
  '0x49ff66d90c67ca99feaa5e8e473396d5c25aa71a',
  '0x4b2a0281dd577e5767442688c1e84ab901a28d46',
  '0x4b3b0a6c0bacb088b6b0b0ba31bab77282068117',
  '0x4d910a111da1a949ab715db9ea81ed65dd7af6f5',
  '0x4de687d92445c81307e66c4f5640a72c093d8762',
  '0x4fb98d1499a24e9c794401e9fb96e9483a43d934',
  '0x504725c17d792c9096b1ed20545c096e22882188',
  '0x513a8a2577056d82b9e9d46c96625a4d4d05687e',
  '0x5168895228e49c785d3d77a4621f076403da79a0',
  '0x524b7c9b4ca33ba72445dfd2d6404c81d8d1f2e3',
  '0x527bb834cc5c8ff730c673880e51372282b06e14',
  '0x539edd96b903c6bfda5a54a28a74a85432309a88',
  '0x53bebd20781aaa3a831f45b3c6889010a706ff9f',
  '0x5467aa7f330356e7628a7a16d4bc1c5891081c4e',
  '0x5491c799e50b472e3bf0162dae268a5783d5ad07',
  '0x5599fe4a9e489a989d51eb6c3b12116910c35394',
  '0x55c8ddb3f486d9f434da6bc24bc756d8d81b2a7b',
  '0x56577f982e2066fec566108f0a63f3d09555c6ca',
  '0x56773dcc20c9715d24d3f69ed58c16c471a3f2b7',
  '0x57050cb9f0c74ba94216907806fc030a277f33e5',
  '0x58335915e11acba82cada466f02ce70668614f9d',
  '0x590f4ca9b70860d1b89be8a7e69f22e59f6dcf6f',
  '0x5d1e5f7e416dfa5d8f222ebe8c12ec39af9e525b',
  '0x5da2690b20cdafb31eee5ccacfd59009326e9e28',
  '0x5e7472223d5d28af97832934dcb9e5408953cefb',
  '0x5f0f6f695febf386aa93126237b48c424961797b',
  '0x5f4ab143df63665940a7c49406e0fde3f0f4558a',
  '0x5fdcef2b7e1585e6a888393d1330c5e5e88c27ec',
  '0x61fd0d043d519f5a2bd05785000f30db96809429',
  '0x62297ef24e02d70d20fdf85b10172e77a16f70b3',
  '0x62cb05fc74548cb01b5d6c9c846bd0c06a42be9e',
  '0x631ec40dbae6ee11590549b5e270628840093a08',
  '0x67ca64e6d0a97bae9d85b6b005a0ccdd876ab896',
  '0x6811eaa638b04aedafeaddfca36c50ea303b1866',
  '0x6926b9af630d085ed636780f57b7febe02150ac5',
  '0x6a3de9a0cb759f634a6a6f66c926d71b055482c4',
  '0x6af7812fc932da2d34738a33f295de54011fd16b',
  '0x6b6ae848f555f70944bc99d736fe29fcaecf8b23',
  '0x6d28fb077eadbbd1d8ceb9c5a4995ab3adf12807',
  '0x6dcb2a373398b17ef9b052d547a3785a9ac6985e',
  '0x6dd20703b80b0b97f87d44f377ea76b692504a13',
  '0x6ec2997cf35457daa9de4cdc989c697d921b5285',
  '0x6ecb6038d923606b3540c4c6ac267c73ab7fa3c5',
  '0x6f3a2812072dc24619ef2349a4ea76b5dbf3cc0e',
  '0x6f73ea756bd57d3adcafb73a4f5fcd750ec1c387',
  '0x7014aecd80adba024f5e9e3104bac79008573ce1',
  '0x70cc2cd17112b1107917131c2e6d939d0b6df194',
  '0x70f275bb99e26c8275dab9ac843535f8e130bc87',
  '0x714f7b06f3482cc8cd0e8072c9d191b40bb8d06e',
  '0x744166a0823f32f17a9a873a2c7fd0e21814c828',
  '0x753f8dab2ce1e6ec33e2967bcdd4974f60025319',
  '0x757a21fbd39cccaff681e1930e273b9add0008db',
  '0x75e1ee1dc2f501c1b23332e92c19f21866960ecd',
  '0x77e8001a83e5175e36b0d80c11fe8b5a65ab5ca3',
  '0x78086ad810f8f99a0b6c92a9a6c8857d3c665622',
  '0x781c7871191e0a6e9699b7b1b7e18f4c9c2409c3',
  '0x7962369691d4b52cbf5ba1eeb39aa6a174f65ce8',
  '0x7a1c5e9dc063a6915855247e96efab3cfc20ad9d',
  '0x7b2c87a10c5c3fd0d838c0516b06c5c2344c57fe',
  '0x7b50b1f2634fa38d078eb067a4f8f22a666b49a2',
  '0x7c5504688eb4f81ed558550066dc218ffee1f6c8',
  '0x7c561e7688d523f044eeddaf6a52ba9cd10a400c',
  '0x7c86fb711078794c75c88c0a945121690365dc84',
  '0x7cafe10a6440eca9570c1428582acf6f734cbd36',
  '0x7d08c340fcfe051c462dcfda8b9ed47642b96b10',
  '0x7de8d83d11162f0eeccedf3bdb6f8d2c3f042d29',
  '0x7ed9cf5dbf2f68bd33ea2c95ef2b4634976ba7f8',
  '0x7f2cf626a94c1a15cf1f208a66f08d8aa5660fcf',
  '0x81dcab1513d88478a3536348d6d7560f2d8762a4',
  '0x82dc39052703cb51718b92fd62a6da6d1e749a0c',
  '0x83ff31faa1fbe034b1bf17ad33759ed85da65e2f',
  '0x84179c31f79683c7aae040d7c9c05789bace912a',
  '0x8471d92cd0d98bee82258c106adf147bde76ed9f',
  '0x84ba38696f4f2dbcb88ec2c5f82d24a074d2e6fe',
  '0x8577243a29f7dd5780ab8843b00a2710ab410e87',
  '0x88668b63218bcfd31692e14f635d195f9f45c030',
  '0x891aa27af08e3bc78e00f239355e7d9b646b0a25',
  '0x8abe2749b67ad7debb7442c46ea4a0e003d4852a',
  '0x8b87a809fc67fdc071841888cff1494b4110d117',
  '0x8c976b2d3725fe86443a0d443f71ecbcf297e577',
  '0x8cc13d5436075f48993e72fedcf459f6cb69b176',
  '0x8efceb29863fb0b62c73c6c9c23f10f24243ebfa',
  '0x8f052c26f086a90059b53d269ea44b79c6be255b',
  '0x8f06975b0c3cc46087134339fb22ee2d46d2106d',
  '0x8fc54e3d572012099f3f48369657d58f653e92e4',
  '0x9058a136e29c3b937c23cde011b4e584fb603c11',
  '0x9108b406f9c7843f6eaf4bc953fb2743b107f62f',
  '0x927f5512911533a526a4ef53a3b959d64af1c960',
  '0x92e23d20fac1e040b665242d3fef2540a14fbbd3',
  '0x9380da47618d355a9eec7a2ec37e50c2df9bca6b',
  '0x9424116b9d61d04b678c5e5eddf8499f88ed9ade',
  '0x953a8dc0e0e2e8fdf8607e5c3c55dd53b2f4fba6',
  '0x95999c47c3e32a337ef108d657675c2757a606ed',
  '0x9631d389a1fa14996d93d3bd1948a4e844eecad7',
  '0x96dc89dbe84970ee42a2f0b35fb50599e6745ff8',
  '0x97460ff37eebc62e9e1d9237e9f3501766fbc2bc',
  '0x97d2b1b4a249ec77d56fd1576546996d14f7db1a',
  '0x98a853c794f8ea6dcac3c6b48c1730580ebde7b7',
  '0x98bdfe7174ed431da22604aaf87d66fe2bd8621e',
  '0x98f1bda2d45ffaca73fcf2154e9e007871e14934',
  '0x9a667dcebfab972af744ac02e6d477945fc24da2',
  '0x9a68254a63b08710901e721352342d3b2d30901c',
  '0x9bd69d51526fc9db09bb831f5069a48cbe4d3421',
  '0x9c213bd7e10a3b88172b300fd5b6953cef579c66',
  '0x9d2579cf50fea00aa7136b5f3d1feae49074c7bc',
  '0x9d2927cca1726f7412e4408046e3e801f3eeb723',
  '0x9e1ccb3c9c55ab371d427745bf213df80cf23141',
  '0x9f128b52128027dd6ea9892f741519979d36fa34',
  '0x9fe176816f90145f8aed9ffdedee158ed8423ba2',
  '0xa1b321dfb73c1bf9aff183c412f53efa07a7583a',
  '0xa1f5c398179132a017e89a747cb097ca4bb1b117',
  '0xa266e8b5c812c6a9d63b8862599f2963d19a797e',
  '0xa28fd178c686beebd6818d6772e56f6e7a6ff5cc',
  '0xa2b8d326f7a684b2f4cff0afcc4ba75781ad0802',
  '0xa2fe7e94d363be04145752115f465ec0214d43b0',
  '0xa3aaf59806b4dd3a08769d07c93bf62470b8db02',
  '0xa495e370e5987babb57580319c2ecb8e52a1239b',
  '0xa5a2bf241cb77022f0e86e810e9a1b15ee529da8',
  '0xa6db3fe6f877b9d5ccd90499206146879ce63415',
  '0xa78b54a5486ce07838c1511c5ad5f72df2ac833a',
  '0xa7dcc417c63f24f9073b667a5d7149bd38463d0f',
  '0xa8879c580a54f190ed53b43d30de269097ad7543',
  '0xa93ee14e133ce344691ee7984162f5a191caf4bc',
  '0xab81be93f28664daf0c1638b483e4f9d2539446e',
  '0xac2eb6591b402c1e2ec0c741ef34c4625803c045',
  '0xad277bcdbe535294d07349886682e0d664bb59a0',
  '0xada00080816c264ece458eee56802c2521c2469e',
  '0xae33247dcd75250c4823db8433c1ed40bd63a27c',
  '0xaeb7d6fd40063e64f741c98f3f8cc846719af17c',
  '0xaf2ed164a141edfb34667dac4d25a2fd9c090663',
  '0xaf4c93d576664fcf60fcc56df130a42773926126',
  '0xb006e407c055350055c197cc00aa38301d916d0c',
  '0xb2e7f7cf519020c8b6ff32a088fec95b03ccc715',
  '0xb37d9262cd3c4346653bc7c0c6b6d9c15e9669d4',
  '0xb4374bd294601a2b69dc6c0de1243f047ebe6e29',
  '0xb4f0bcadbefcee813981d40e0960ffc0ae770ec6',
  '0xb55d65402e87ae6e0d0a40244fc5f8b943dac044',
  '0xb67db0960ccd926efba7fe34018f025086b38473',
  '0xb6cf777e3696a502107417265c92d1b075636a10',
  '0xc01b3274cee6dba296add4002972f579c8999d0a',
  '0xc02f533a819c4d3149544dd1a55cf7cc87a8d30b',
  '0xc1eca986c20103f3a180d797f5be64702bae7b22',
  '0xc2b35534e47cdf3d787e01630129631d8270abfb',
  '0xc2ec556af69fc6d7d708f1d111d5860583982d5b',
  '0xc5633bc0bcc3897117841f434a8f5f95a724a7b8',
  '0xc659fd78a395d70ec69ff034b4378a712e7edb64',
  '0xc72f40a397453051349f73cf2e2a04fac06e37a3',
  '0xc7bcbe313ed75e385498077ae32c1246a4ca7286',
  '0xc7cf0044e1f2a151c903ccd9ab30d6b09bae9565',
  '0xc7ec028c706ec2fcfdcf66bba7e80ded634f6ca9',
  '0xc85b3cbf81e63e0524717efc4e53dc12658b1fc5',
  '0xc9646794d03c53bc9a3b4ad60188a0b1d962e8ff',
  '0xca7e077ab56ba580f7dbe6e50cb2468827bcc40e',
  '0xcb06bede7cb0a4b333581b6bdcd05f7cc737b9cc',
  '0xcb93531eb1e872cb98bf8118c310d06475a19e60',
  '0xcc4d9ca24d6ec7f8e11d917f5aa3a2010f6f3969',
  '0xcc72d9e9525b55297c03c2d09b9677617a02d156',
  '0xccf92004887dae63679519278c17c7feca6cecb7',
  '0xce8ad80ce1a979381d209ac230d02adafb9fa897',
  '0xce90a7949bb78892f159f428d0dc23a8e3584d75',
  '0xcee53c7318ff49571ee1d889e40f3774c7c98b80',
  '0x05774ae45dba197610cf225126317c10692e3771',
  '0xd02cf646520827d74d4ff0435f96220819772b4b',
  '0xd0d289dae2bd66ddc6dbd32f1537426bf71141a0',
  '0xd11d1c63911a42a2435256c013e4b88313dee2e3',
  '0xd1d32b212f6fcaf0240ef557d8ef3ccc3a7b843b',
  '0xd296dd3b19f72bebb4ea824babab096de84b994b',
  '0xd2b12f1a75b2313980d7c410002e6a48cf389b8d',
  '0xd2d667b236cf6dc95db080f9a8e6b1ce13971dd5',
  '0xd353a1a0051175b4ddacc8e6e6ee5f3976b907e8',
  '0xd3fac37e04a928f581459ea81d7b1e2dc64c2fbb',
  '0xd4469b51c1b892b0e5868f2773b74f3b7539c0cd',
  '0xd478df699a99eb1921c7993b1aba4194cdb490e4',
  '0xd76d392d17379c22ca5e49d167bad1dcaf7eba0d',
  '0xd7f47f4932f65c2bc1650e6f10218a0528543437',
  '0xd86057c793bdfb77bb11dcabfe054ee4fd86ebdd',
  '0xd91dc7c83bd01b91cb25019dfc4e35bc6faab814',
  '0xd9fa295d29f022d34028182329b051cbceb92579',
  '0xdac6a6bf61f10d57d7156348a5400ed0b9aa1fb3',
  '0xdc7bfc3058654da90692457f68ee3a34e2dd3908',
  '0xdcae87821fa6caea05dbc2811126f4bc7ff73bd1',
  '0xdcee323662c16b208fe4f9f12b1f76c161aca763',
  '0xde3e1da19ea0c90fcf70b5f1a8d2fd7fce7151e4',
  '0xde8328c4f5ceeb2c2b3e836b3354413161c81285',
  '0xdf36b093cbc06522b68105c3d9141db188e980b6',
  '0xe01a97fc4607a388a41626d5e039fdecbfc6acd9',
  '0xe05adcb63a66e6e590961133694a382936c85d9d',
  '0xe0f8c0fcdf09a3b9ad474f128ed9d4269edddcea',
  '0xe1268b49d3e4e559f466239df4bee1d5cae04356',
  '0xe15cf0ede90e9059188fc8439014d1ffe29e0bbc',
  '0xe2f00c430b36d8a6b389f909340d0f2dff93b876',
  '0xe39bd9e3af2054dd193b0d0217cfbd460f104902',
  '0xe434f59930e9cd2dfd2235ea86c9bfdb44f5bc0f',
  '0xe4e848c9e08133d85acb1cd09f631b3dee54235a',
  '0xe6a5F0bb03c43196D43bAdd08899396C224b8e31',
  '0xe6f31dc1cbc6dede46b20d087049af6c252ece96',
  '0xe744544f50fc6bde59cd8ed3e656487d4791357f',
  '0xDE11c3cb09b437d148E5eE6B909178EC1CB19750',
  '0xe96ead0ad625122677684448155ba4ae2700814d',
  '0xe974e75da812e3764dcc746c8fa82e25fb9f0627',
  '0xe9976059d92d5bdf8464b55aa048c4343fa01886',
  '0xe9d53246209e4cd87dbc5d1fee71f44bff89a611',
  '0xea1126e70185400a2cf0de2a6b35428fb8affd29',
  '0xeab3739d4e716bb07901d72dc94b4139b6968623',
  '0xeaf7814cdf7236bc5992d19cc455f9c92b00aa9e',
  '0xed6ff3a2c8a13f2223f6a7b3985e54e1f8dc064b',
  '0xeebd455c141750d4ebf27d66be57dd3c7aa3e554',
  '0xef1de2fa338fb55e834bd6cb8bad382d410cb882',
  '0xef572e922be63f90219e4ba674a51d840f74bb1d',
  '0xf1199b160fa5c2c9f5e38c9c92d8cc3046c7f9aa',
  '0xf19aed29a6cd4f5a1b3c8ed79d987e3fc1dca853',
  '0xF1ce03F0f25304a1Fb911010F6e3232390Ab121d',
  '0xf1e1c701b49b1dc2405a9e8ef40f9f88802b80fa',
  '0xf262322e89db9c9fe03b4c9591218810243b0cdd',
  '0xf29d4d7b3270beb3c54ee703fe2d9d17bf806c81',
  '0xf38f67d8b16752b18f7358ef216a35423e45f806',
  '0xf433bdbf45ae8396805f5882c9f395b246e62af8',
  '0xf531027f908e0a0a3f2c5a489280be58b7b1d905',
  '0xf532920bb32122d6475ad4cc7634dc3a69631902',
  '0xf53efc8469285437acb24a650463a5afb50dd800',
  '0xf5671d951c0aa6e4bd69a854fc2d15fe707ddd0e',
  '0xf5993d2217ab9d7f1d685a21524449108b34ff69',
  '0xf60602b9f454eba8e54a694ebc416585316f93ca',
  '0xf6d47763f157f42e8bd711a3b41510267eaf4ba1',
  '0xf74b25d3f6616e51b2d8be6c8cec54b045229655',
  '0xf7764568b698e5954892c00ed7d9a390b105c5f7',
  '0xf7a8fe61aa0b9051f12f72695fb3ad7546097a01',
  '0xf9270609374b754ca26cf00248c0aed197280909',
  '0xf987a65115b267bc9a16f0eb52b7f7e94e554cbb',
  '0xf9b7d79932b16c6bf8d08dbce15cd5e6942dd18f',
  '0xfb666c44fc6fbf119f710d2197a4256c3c832643',
  '0xfcf080d18e260a01246fd5c6e8a893a7def4d369',
  '0xfd4ff3a8e81942e9452582b6f2fad64a5c894836',
  '0xfdd9a3e4c07756c1dc31ba938fc062d45eab1668',
  '0xfe2b8dc957459ac2a08fef57576bcb8a89845d8b',
  '0xfe2e3e999bc785bb053c85688da6daa4a19eb0f4',
  '0xff7efba11a8888d6974997d4a8940bf83cd7a580',
  '0xffcad6bba49e6a63489c078c3a4a9298ee21cfab',
  '0x3d7f2165d3d54eaf9f6af52fd8d91669d4e02ebc',
  '0x7b640407513bc16167ef3450fd6339803982e976',
  '0x8dd62416fa3c6efada57eb7a6383885045390204',
  '0xab6ca2017548a170699890214bfd66583a0c1754',
  '0xbddb878b393bf91f8d34b2edf7087ca529e3ccaf',
  '0x8e63e4376dde62cb82f5a91c4bce4156457dedb1',
  '0xca3872b1f2203c4b488955f6d8fc28c35315b507',
  '0xd35fC346e15BA5b446917C9fD23A9471d6144701',
  '0xb0bdd53b627d7e61cfc5c13ef110e47e210fac6f',
  '0x01876c26C13B9916853b97033F89F4ab0b5001Ca',
  '0x03ee832367E29a5CD001f65093283eabB5382B62',
  '0x04D253FbDd4501C383e35dea2663a053B7439904',
  '0x061723E247Dc58B10D1F5f1B6e536ab26bA4ce22',
  '0x092cd1A6d222a167F5D0767e6444C8b45C92cc72',
  '0x0CE8311ec9c72772D7DE50fB406c8650840eEfA5',
  '0x11502311A80736dad252Cc8cf95125DFc041fd15',
  '0x11F46FFf2624737Aa0D38507E761B6Ef770Eb94D',
  '0x1590886F763773eD89E2cb377ECC2545a8761924',
  '0x1996DA1e0C54E113370729d1C22F84376F5830e9',
  '0x1f8A5fEb81bE9a44Ff57d2a60C0420FB19fc2DdF',
  '0x1fAD9f65B39d235469BCB59Bc664872B93EEcAc5',
  '0x273BD0BaFa504888bC2d1bda37A9Df19038bA3f5',
  '0x27a5dfB8eCA8bFcC6B0725c48c233764dF858Dab',
  '0x29f088cfF86F03036220393C869519329bf69CA7',
  '0x2B6F0ce4A83A7db2db60867303ccc1b96FEC8016',
  '0x2ec4a2BCd4f33c7c9AaFaB7Cfa865ec15508bf62',
  '0x3392Da275706938613d08777D1f36dB1e1C27730',
  '0x33BfD3bA3BD175148D1bB1eE87e865253fD265E5',
  '0x37DfAa62F0fE5e6ebe990ef1a0722F5279962E37',
  '0x397fBd1E27352175cC5B6baf171A30911268823c',
  '0x3A37A4D9d20c99060e19B066C11ACa292dEDFA35',
  '0x3a7c8Cc17fDCAb6f7aC1EED62A4559021Bd115fD',
  '0x3DDc23294De66cC7C08dEB620bA68C77Fa306D0B',
  '0x3F03441C347ED025f5A03933fC8753BfEf75F02e',
  '0x3feD9E5B07C89BD07d4cBb6415d40d4EF37d4d24',
  '0x403089B876128B97012AEe31e3f91B4f6CfEBEde',
  '0x426eC35edeeCcdae5a4C3E7e3782ADB36A80aECF',
  '0x454908004F88436F46eFAC5578b4984fE1375206',
  '0x45BC508822A92Ba95a1371C743f785c574920069',
  '0x478bb542f7658d635ABBA67edB987806DFF5b83d',
  '0x4974F15922583584bAAE4A71930B387510E21f11',
  '0x50D2D7c34FB82d94502a2A7992376513C00C91ba',
  '0x557c60995797fa7b47Be105227A2e46148D85750',
  '0x56bDc5fE7f9752E7F5a381E394acFE72A724462b',
  '0x5aa71418Ac6238c78eA9A032a768DBbbf86bFecA',
  '0x5b513d7bB62F7F28bc3ABA2d52FE6167A9b3a827',
  '0x5B55F22b89fa7b7179359f12177F75D7F1Ce61b4',
  '0x5c70aF3ddc7e815a40F2A09d22483e6E24EdDf8E',
  '0x6140F00e4Ff3936702E68744f2b5978885464cbB',
  '0x61956c07e2499d10a36b01E73bdf56B97Efb63AD',
  '0x628379D497562d794A4532941C9302eA9Fb18e8B',
  '0x62cd63E7203F53476ea8956276238fE839a23103',
  '0x6486d5DBF01F1Edc446c87220003F49EDB43972F',
  '0x66298837Aa0079C0D1a4ff7549F2Aac8A79c383e',
  '0x665654f2D2a151bE2D0F8E3697e6Ce780F732aF2',
  '0x6B0846740Da0a586745e1Dd3D8E2058c467E3069',
  '0x6b72b861AadEe7A4e97A196AEd89eFd29fb24Ab8',
  '0x6CF69059DD4a86987Bc8A4C334b83ea8fa9f4037',
  '0x6db8E922741C8bc816B30E8c944C3002c3b6098C',
  '0x6E08a7b853722eD42B43f85b626e242E7256842f',
  '0x6e0D262cE9C6905CC9d75c05CfB5aBF37B7aaf63',
  '0x722E2E4c15bE1fDDEd3C86f4100bC32b181827F5',
  '0x771699E05Bb2E147DF732638D052b044DC16eeb6',
  '0x77b89feF8300b30806155f9c36AAC4C3B5f22f8B',
  '0x79e561168835c783240a0637320D308897BD0922',
  '0x7c0eC1a11cC89deBD3A6bF35A5811833FB6AF30a',
  '0x7cD29e0df7e442068E5751b638c9086fb62C50fA',
  '0x80cE7206dA1eD2804D645a91705212Ed742f285D',
  '0x8179e48D959a4c41D5E0432Ac05b30707149d649',
  '0x833B6cac387e694dadb02B26d0c55e346b4C9C34',
  '0x847768Fa647DE3F5735d6fb7638979eaD0948aEa',
  '0x8600BFd5d4AF01178E7fED1A26027f6C669C923B',
  '0x8af62aF89842a9094A6f4C09186A19cc0f7254b0',
  '0x8b501A6090Fd3c6b905B1A0099E2E216a5a2D40d',
  '0x8F802E4BD94e7adDEAC5F1E679bDf5178ECB1bEb',
  '0x933fb91573AB07d14d40f259fA205f6b2D0D5a6c',
  '0x9366c209d94604C7Ba5bd6E472D1AD46CB029FC3',
  '0x9e189c74307412DC4cCc98D7a18a4C606962a509',
  '0xA0258D37662453dCaed52Ead82c971eB60AB0cfb',
  '0xa0BB9522727904afA7d0f4d3e14d307cb2c0c894',
  '0xa5855f4F40a409fFDe3a171eB616926AEc5D7B9C',
  '0xa7a9593478dc4c4f191Cc97a88da0C1299aF0355',
  '0xA7CAA341dB351930ea284180CC20e1846CFbD1CB',
  '0xA8174ABc36B3298356D273E512CD87223a2b3FFA',
  '0xA8bA075E79241d0771FC4237174c1A8D67eDf7D2',
  '0xaa9Bff236cF51518dA774714D50ad07Db5149479',
  '0xB09b570c1AB9Db04817047269eafCDDf2c215682',
  '0xB16525385824E8cf877be513386e75d875043Ffd',
  '0xB6E32c75A2083a38F6cCe6e65756c0Bf86D84200',
  '0xbA738302746E83700C72c532149F4f396356bDD5',
  '0xbC7b2461bfaA2fB47bD8f632d0c797C3BFD93B93',
  '0xbdc09A82647034b29E1E8c3f6F8081Ee1F46C3d7',
  '0xbDDe100544020fC3Dd072bf4Ce9a62845e6c9884',
  '0xbddf499207d29E920c0500642567b43238b30fD3',
  '0xBff456923E74Ed77d9E8acC2a7d9FF52D8c67Bf6',
  '0xc02E6b0D0c1A5D8cd26BeebA0Fe8d76C5d2f19B9',
  '0xc1b19635aBFD6C454C0B074383503c3Fe561aee1',
  '0xC2D742FdBB31442B843A098dCEc3C20054c28986',
  '0xC45920062985116EAaC6589058Ed337066D6f2e6',
  '0xC4D4125c83d1108df969DdA998227FcA80fd82dc',
  '0xc4F3f37F7020FE4d354e18618BEA5c52e3775ee2',
  '0xC579f518bcE44908939a226775129922a0503adc',
  '0xC60dFD0638600eb84d8B6431C76DACc0968C0cA6',
  '0xc65EE9634028d4c98F3D9C43233E6d06A39ABe5C',
  '0xc68C7771EC6a6E5D67D62aa9c6f22df69865e401',
  '0xc8F42217B6B1768012b4431f922Be0759Fed7397',
  '0xc925C3608b38b7d1FCd15686B0726DD35813C526',
  '0xC97958ff5370c56B7E0e6fB58437F00702714D49',
  '0xcf88FA6eE6D111b04bE9b06ef6fAD6bD6691B88c',
  '0xD2B481b6e48F9Ef4204EB6061BA7cf33dadCC286',
  '0xd3364EA1C742Cc2d396aC65e1723b8d608a939bb',
  '0xD3a74341aDAc943De6600468393Bb6Ca4431A7Fd',
  '0xd3ca7d35C840bA15c48deF3EB2A800c4F588558c',
  '0xd42D52b709829926531c64a32f2713B4Dc8eA6F6',
  '0xD76040b01fa18843F8983Dbb1B40d3a64b5B771A',
  '0xda3c325aB45b30AeB476B026FE6A777443cA04f3',
  '0xDEfc79316c9e12f2C5d82aB3bf10861cE38cE3cF',
  '0xDf85b9146D73a852D7B900d1ABD974f2a8119f57',
  '0xe30337c6C904Cf7b44C7E374eC427873c7aeC95e',
  '0xF010306661d162B257F93dc8902A7c79DB8A3280',
  '0xf02Cd6f7b3d001b3f81E747e73A06Ad73CbD5E5b',
  '0xF0C11838870ca444F5DC0BA0271a36bb9D9F81eC',
  '0xF1Da6E2d387e9DA611dAc8a7FC587Eaa4B010013',
  '0xF408A40E46a3953684985fa46a625bA1B5573a9B',
  '0xf4140f2721f5Fd76eA2A3b6864ab49e0fBa1f7d0',
  '0xf8986Ce1FA0A656ee8D7e306c9182Db06C6d6449',
  '0xf9EEea18A0aE0b90D0840D2B8501b4687822C2F1',
  '0xeC9e512fE7E90134d8ca7295329Ccb0a57C91ecB'
].map((addr) => addr.toLowerCase());
// console.log('Allowlist addresses: ', allowlistAddresses);

// const merkleProof = generateUserMerkleProof(
//   allowlistAddresses,
//   account.address
// );

const MinterMerkleV5Interface = ({
  coreContractAddress,
  mintContractAddress,
  projectId,
  artistAddress,
  scriptAspectRatio
}: Props) => {
  // console.log(
  //   'coreContractAddresscoreContractAddresscoreContractAddresscoreContractAddresscoreContractAddress',
  //   coreContractAddress,
  //   mintContractAddress,
  //   projectId,
  //   artistAddress,
  //   scriptAspectRatio
  // );
  const account = useAccount();
  const balance = useBalance({
    address: account.address
  });

  // Generate the Merkle root for artist to upload to the minter contract
  // const merkleRoot = getMerkleRoot(allowlistAddresses);
  // console.log('Merkle root: ', merkleRoot);

  const [projectStateData, setProjectStateData] = useState<any | null>(null);
  const [projectPriceInfo, setProjectPriceInfo] = useState<any | null>(null);
  const [projectConfig, setProjectConfig] = useState<any | null>(null);
  const [verifyAddress, setVerifyAddress] = useState<any | null>(false);
  const [remainingInvocations, setRemainingInvocations] = useState<any | null>(
    null
  );
  const [merkleProof, setMerkleProof] = useState(null);

  console.log('merkleProof', merkleProof);
  console.log('[merkleProof?.[0]]', [merkleProof?.[0]]);
  useContractReads({
    contracts: [
      {
        address: mintContractAddress as `0x${string}`,
        abi: MinterMerkleV5ABI,
        functionName: 'processProofForAddress',
        args: [merkleProof, account.address]
      },
      {
        address: mintContractAddress as `0x${string}`,
        abi: MinterMerkleV5ABI,
        functionName: 'projectRemainingInvocationsForAddress',
        args: [BigNumber.from(projectId), coreContractAddress, account.address]
      }
    ],
    enabled: merkleProof != null && account.isConnected,
    watch: true,
    onSuccess(data) {
      setVerifyAddress(data[0]);
      setRemainingInvocations(data[1]);
    }
  });

  useEffect(() => {
    if (account.isConnected) {
      // fetch(
      //   `${MERKLE_PROOF_API_URL}?contractAddress=${coreContractAddress}&projectId=${projectId}&walletAddress=${account.address}`
      // )
      //   .then((response) => response.json())
      //   .then((data) => console.log('MERKLE_PROOF_API_URL', data));
      // setMerkleProof(
      //   generateUserMerkleProof(allowlistAddresses, account.address)
      // );

      if (
        account.address &&
        allowlistAddresses.includes(account.address.toLowerCase())
      ) {
        const merkleProof = generateUserMerkleProof(
          allowlistAddresses,
          account.address
        );

        setMerkleProof(merkleProof);
      } else {
        setMerkleProof(null);
        setVerifyAddress(false);
      }
    } else {
      setMerkleProof(null);
      setVerifyAddress(false);
    }
  }, [account.address, account.isConnected]);

  const { data, isError, isLoading } = useContractReads({
    contracts: [
      {
        address: coreContractAddress as `0x${string}`,
        abi: GenArt721CoreV3_EngineABI,
        functionName: 'projectStateData',
        args: [BigNumber.from(projectId)]
      },
      {
        address: mintContractAddress as `0x${string}`,
        abi: MinterMerkleV5ABI,
        functionName: 'getPriceInfo',
        args: [BigNumber.from(projectId), coreContractAddress]
      },
      {
        address: mintContractAddress as `0x${string}`,
        abi: MinterMerkleV5ABI,
        functionName: 'projectMaxHasBeenInvoked',
        args: [BigNumber.from(projectId), coreContractAddress]
      }
    ],
    watch: true,
    onSuccess(data) {
      console.log('data[2]', data[2]);
      setProjectStateData(data[0]);
      setProjectPriceInfo(data[1]);
      setProjectConfig(data[2]);
    }
  });

  if (!data || !projectStateData || !projectPriceInfo || isLoading || isError) {
    return null;
  }

  const invocations = projectStateData.invocations.toNumber();
  const maxInvocations = projectStateData.maxInvocations.toNumber();
  console.log('projectStateData', projectStateData);
  const maxHasBeenInvoked = projectConfig;
  console.log('projectConfig', projectConfig);
  const currencySymbol = projectPriceInfo.currencySymbol;
  const currentPriceWei = projectPriceInfo.tokenPriceInWei;
  const priceIsConfigured = projectPriceInfo.isConfigured;
  // console.log('projectPriceInfo', projectPriceInfo);
  const isSoldOut = maxHasBeenInvoked || invocations >= maxInvocations;

  const isPaused = projectStateData.paused;
  const isArtist =
    account.isConnected &&
    account.address?.toLowerCase() === artistAddress?.toLowerCase();
  const isNotArtist =
    account.isConnected &&
    account.address?.toLowerCase() !== artistAddress?.toLowerCase();
  const artistCanMint = isArtist && priceIsConfigured && !isSoldOut;
  const anyoneCanMint =
    isNotArtist && priceIsConfigured && !isSoldOut && !isPaused;

  return (
    <Box>
      <MintingProgress
        invocations={invocations}
        maxInvocations={maxInvocations}
        maxHasBeenInvoked={maxHasBeenInvoked}
      />
      {priceIsConfigured && (
        <MintingPrice
          startPriceWei={currentPriceWei}
          currentPriceWei={currentPriceWei}
          endPriceWei={currentPriceWei}
          currencySymbol={currencySymbol}
        />
      )}
      <MinterMerkleV5Button
        coreContractAddress={coreContractAddress}
        mintContractAddress={mintContractAddress}
        projectId={projectId}
        priceWei={currentPriceWei}
        currencySymbol={currencySymbol}
        isConnected={account.isConnected}
        artistCanMint={artistCanMint}
        anyoneCanMint={anyoneCanMint}
        scriptAspectRatio={scriptAspectRatio}
        verifyAddress={verifyAddress}
        remainingInvocations={remainingInvocations?.mintInvocationsRemaining.toNumber()}
        merkleProof={merkleProof}
        verifyBalance={
          balance?.data?.formatted! >=
          utils.formatEther(projectPriceInfo.tokenPriceInWei.toString())
        }
        isPaused={isPaused}
        isSoldOut={isSoldOut}
      />
    </Box>
  );
};

export default MinterMerkleV5Interface;
